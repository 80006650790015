import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Text,
  useBoolean,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { EMAIL_PATTERN } from '../commons/constants'
import { FormInputType } from '../orval/loov'
import { FormSlideInputsProps } from './FormSlideInputs'

type FormSlideInputsPresentationProps = FormSlideInputsProps

const FormSlideInputsPresentation: FC<FormSlideInputsPresentationProps> = ({
  inputs,
  privacyPolicyUrl,
  privacyPolicyText,
  privacyPolicyCheckboxText,
  buttonText,
  onSubmit,
}) => {
  // プラポリが設定されていない場合は、常に同意済み扱いとする
  const [isPrivacyPolicyAgreedWith, setIsPrivacyPolicyAgreedWith] = useBoolean(!privacyPolicyUrl)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, string | string[]>>({})

  const privacyPolicyTextNode = useMemo(() => {
    // %{} で囲まれた部分をリンクにする
    const match = privacyPolicyText.match(/(.*?)%\{(.+?)\}(.*)/s)
    if (!match) {
      return privacyPolicyText
    } else {
      return (
        <>
          {match[1]}
          <Link
            href={privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
            color="blue"
            data-testid="form-slide-input-privacy-link"
          >
            {match[2]}
          </Link>
          {match[3]}
        </>
      )
    }
  }, [privacyPolicyText, privacyPolicyUrl])

  return (
    <VStack as="form" noValidate spacing="16px" w="300px" onSubmit={handleSubmit(onSubmit)}>
      {/* 入力項目 */}
      {inputs.map((input) => (
        <FormControl key={input.id} isRequired={input.required} isInvalid={!!errors[input.id]}>
          {input.type === FormInputType.SELECT ? (
            // 単一選択
            <>
              <FormLabel pos="absolute" top="-12px" left="6px" fontSize="13px" bg="white" zIndex={2}>
                {input.name}
              </FormLabel>
              <Select
                {...register(input.id, {
                  ...(input.required && { required: '必須項目です' }),
                })}
                h="40px"
                fontSize="18px"
                placeholder={input.placeholder}
                data-testid="form-slide-input-single-select"
              >
                {input.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label || option.value}
                  </option>
                ))}
              </Select>
            </>
          ) : input.type === FormInputType.MULTI_SELECT ? (
            // 複数選択
            <>
              <FormLabel>{input.name}</FormLabel>
              <Controller
                control={control}
                name={input.id}
                defaultValue={[]}
                render={({ field }) => (
                  <CheckboxGroup colorScheme="watermelon" value={field.value as string[]} onChange={field.onChange}>
                    <Wrap data-testid="form-slide-input-multiple-select">
                      {input.options.map((option) => (
                        <WrapItem key={option.id}>
                          <Checkbox value={option.id}>{option.label || option.value}</Checkbox>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </CheckboxGroup>
                )}
              />
            </>
          ) : (
            // テキスト入力
            <>
              <FormLabel pos="absolute" top="-12px" left="6px" fontSize="13px" bg="white" zIndex={2}>
                {input.name}
              </FormLabel>
              <Input
                {...register(input.id, {
                  ...(input.required && { required: '必須項目です' }),
                  ...(input.type === 'EMAIL' && {
                    pattern: { value: EMAIL_PATTERN, message: 'メールアドレスの形式で入力してください' },
                  }),
                })}
                h="40px"
                fontSize="18px"
                placeholder={input.placeholder}
                data-testid="form-slide-input-email-input"
              />
            </>
          )}

          <FormErrorMessage fontSize="11px">{errors[input.id]?.message}</FormErrorMessage>
        </FormControl>
      ))}

      {/* プライバシーポリシーの同意 */}
      {privacyPolicyUrl && (
        <>
          <Text fontSize="15px" whiteSpace="pre-line" data-testid="policyTextNode">
            {privacyPolicyTextNode}
          </Text>

          <Checkbox
            colorScheme="watermelon"
            isRequired
            checked={isPrivacyPolicyAgreedWith}
            onChange={(e) =>
              e.currentTarget.checked ? setIsPrivacyPolicyAgreedWith.on() : setIsPrivacyPolicyAgreedWith.off()
            }
            data-testid="form-slide-input-privacy-policy-checkbox"
          >
            {privacyPolicyCheckboxText}
          </Checkbox>
        </>
      )}

      {/* Submit ボタン */}
      <Button
        type="submit"
        w="100%"
        h="44px"
        p="6px"
        borderRadius="4px"
        colorScheme="watermelon"
        fontSize="20px"
        fontWeight="normal"
        // プラポリに同意しないとsubmitできない
        isDisabled={!isPrivacyPolicyAgreedWith}
        data-testid="form-slide-input-submit-button"
      >
        {buttonText}
      </Button>
    </VStack>
  )
}

export default FormSlideInputsPresentation
