import { Button, HStack, Icon } from '@chakra-ui/react'
import { FC } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import Body from '../../elements/typography/Body'
import useScenarioPlayer from './useScenarioPlayer'

const ScenarioPlayerGoBackButton: FC = () => {
  const { goBack } = useScenarioPlayer()

  return (
    <Button variant="link" borderRadius={0} onClick={() => goBack()}>
      <HStack spacing={0} color="gray.basic">
        <Icon as={BiChevronLeft} boxSize="20px" />
        <Body fontSize="13px" fontWeight="medium">
          戻る
        </Body>
      </HStack>
    </Button>
  )
}

export default ScenarioPlayerGoBackButton
