import { Heading, HeadingProps } from '@chakra-ui/react'
import { FC } from 'react'

const H1: FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading as="h1" fontSize="1.5rem" fontWeight="bold" lineHeight="140%" letterSpacing="0.96px" {...props}>
      {children}
    </Heading>
  )
}

export default H1
