import { useBoolean } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'

export default function useHover<T extends HTMLElement>() {
  const ref = useRef<T>(null)
  const [isHovered, setIsHovered] = useBoolean()

  useEffect(() => {
    const target = ref.current
    if (!target) return

    target.addEventListener('mouseenter', setIsHovered.on)
    target.addEventListener('mouseleave', setIsHovered.off)
    return () => {
      target.removeEventListener('mouseenter', setIsHovered.on)
      target.removeEventListener('mouseleave', setIsHovered.off)
    }
  }, [setIsHovered])

  return { ref, isHovered }
}
