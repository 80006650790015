import { menuAnatomy, modalAnatomy } from '@chakra-ui/anatomy'
import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

/**
 * LOOV用にChakra UIのthemeを拡張する
 * Figmaを参照: https://www.figma.com/file/z1P541WUCOmJQC4RCuZjSW/LOOV-UI?node-id=294%3A1937&t=N8rX1iYZXlTX9y19-0
 */

const { definePartsStyle: defineModalPartsStyle, defineMultiStyleConfig: defineModalStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)
const { definePartsStyle: defineMenuPartsStyle, defineMultiStyleConfig: defineMenuStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const theme = extendTheme({
  globals: {
    body: { color: 'black.basic' },
  },
  fonts: {
    body: 'Quicksand, Zen Maru Gothic',
    heading: 'Quicksand, Zen Maru Gothic',
  },
  lineHeights: {
    base: 1.4,
  },
  colors: {
    white: '#FFFFFF',
    background: '#F3F3F3',
    primary: {
      dark: '#009B89',
      basic: '#46BEB0',
      lighter: '#9DDBD4',
      lightest: '#E1F2F0',
      50: '#ECF8F7',
      100: '#CAECE8',
      200: '#A8E1DA',
      300: '#86D5CB',
      400: '#64C9BD',
      500: '#42BDAE',
      600: '#35978C',
      700: '#287169',
      800: '#1B4B46',
      900: '#0D2623',
    },
    secondary: {
      dark: '#C27500',
      basic: '#E08700',
      lighter: '#FFF1DC',
      lightest: '#FFF1DC',
      50: '#FFF5E5',
      100: '#FFE3B8',
      200: '#FFD08A',
      300: '#FFBE5C',
      400: '#FFAC2E',
      500: '#FF9A00',
      600: '#CC7B00',
      700: '#995C00',
      800: '#663D00',
      900: '#331F00',
    },
    alert: {
      dark: '#C60000',
      basic: '#E00000',
      lighter: '#F4AFAF',
      lightest: '#FFEAEA',
      50: '#FFE5E5',
      100: '#FFB8B8',
      200: '#FF8A8A',
      300: '#FF5C5C',
      400: '#FF2E2E',
      500: '#FF0000',
      600: '#CC0000',
      700: '#990000',
      800: '#660000',
      900: '#330000',
    },
    watermelon: {
      500: '#FC6574',
      600: '#E25A68',
    },
    black: {
      dark: '#101B19',
      basic: '#062824',
      light: '#AAB5B3',
      lighter: '#BECCCA',
      lightest: '#EEF1F1',
      50: '#eff6f4',
      100: '#d5e1df',
      200: '#b9ceca',
      300: '#9bbab4',
      400: '#7da79f',
      500: '#648d85',
      600: '#4e6e68',
      700: '#394e4a',
      800: '#222f2c',
      900: '#09100f',
    },
    gray: {
      dark: '#606560',
      basic: '#8A918A',
      lighter: '#E5E9E5',
      lightest: '#EEEEEE',
      50: '#ecf5ec',
      100: '#d6dbd6',
      200: '#bdc1bd',
      300: '#a3a8a3',
      400: '#8a8f8a',
      500: '#717671',
      600: '#575c57',
      700: '#3d413d',
      800: '#232823',
      900: '#050f05',
    },
    cutVideoProgress: {
      500: '#ED776B',
    },
  },
  components: {
    Button: defineStyleConfig({
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full',
      },
      sizes: {
        md: {
          fontSize: '0.8125rem',
        },
      },
    }),
    Modal: defineModalStyleConfig({
      baseStyle: defineModalPartsStyle({
        overlay: {
          h: 'var(--chakra-vh)',
        },
        closeButton: {
          bg: 'primary.lightest',
          color: 'primary.dark',
          borderRadius: 'full',
          _hover: { bg: 'primary.lighter' },
        },
      }),
    }),
    FormLabel: defineStyleConfig({
      baseStyle: {
        w: 'fit-content',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
      },
    }),
    Input: defineStyleConfig({
      baseStyle: {
        field: {
          _placeholder: { color: 'black.lighter' },
        },
      },
      sizes: {
        sm: { field: { borderRadius: 4 } },
      },
    }),
    Textarea: defineStyleConfig({
      baseStyle: {
        _placeholder: { color: 'black.lighter' },
      },
    }),
    Menu: defineMenuStyleConfig({
      baseStyle: defineMenuPartsStyle({
        item: {
          _focus: { bg: 'primary.lightest' },
        },
      }),
    }),
  },
})

export default theme

export const embeddedPlayerTheme = extendTheme(theme, {
  styles: {
    global: {
      // Chakra のデフォルトでは { body:{ minH: 100% } } となっているが、shadowDOM に高さが設定されると困るので 0% にする
      body: { minH: '0%' },
    },
  },
  // 外部サイトへ埋め込みで使う場合は、フォントはそのサイトのものを使う
  fonts: { body: `"Noto Sans JP"`, heading: `"Noto Sans JP"` },
  // チェックボックスをpx指定で使う
  components: {
    Checkbox: {
      sizes: {
        md: {
          control: defineStyle({
            color: 'gray.basic',
            boxSize: '15px',
          }),
          icon: defineStyle({
            fontSize: '11px',
          }),
          label: defineStyle({
            fontSize: '15px',
            ml: '15px',
          }),
        },
      },
    },
  },
})
