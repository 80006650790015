import { Box, Center, Circle, HStack, Text, VStack } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import useIsMobile from '../hooks/useIsMobile'
import { PublishedSelectionOption, PublishedSelectionSlide, SelectionSlideDirection } from '../orval/loovPublic'
import TouchIcon from './elements/icons/TouchIcon'
import SlideIn from './elements/transitions/SlideIn'

export type SelectionSlidePlayerPresentationProps = {
  publishedSelectionSlide: PublishedSelectionSlide
  useIsMobile: typeof useIsMobile
  onMount: () => void
  onSelect: (option: PublishedSelectionOption) => void
}

const optionBgColors = ['#EFDFD5', '#DEE9EB', '#E7E7DB', '#EDDFDE']

const SelectionSlidePlayerPresentation: FC<SelectionSlidePlayerPresentationProps> = (props) => {
  const isMobile = props.useIsMobile()
  const content = props.publishedSelectionSlide.publishedSelectionSlideContent
  const options = content.publishedSelectionOptions
  const { onMount } = props

  useEffect(() => {
    onMount()
  }, [onMount])

  return (
    <VStack spacing="24px" w="100%" h="100%" p={['16px', '24px', '32px']}>
      <Center flexGrow={1}>
        <Text m={0} fontSize={['16px', '20px', '25px']} fontWeight="bold" align="center" data-testid="guide-text">
          {content.text}
        </Text>
      </Center>

      {!isMobile && content.direction === SelectionSlideDirection.ROW ? (
        // デスクトップ画面、かつ横並び設定のとき
        <HStack spacing="24px" w="100%" h="80%" justify="center" data-testid="desktop-row-display">
          {/* ボタン */}
          {options.map((option, index) => (
            <Box key={option.id} w="20%" h="100%">
              <SlideIn direction="up">
                {/* 外側の領域 */}
                <Box
                  as="button"
                  w="100%"
                  h="100%"
                  py="12px"
                  px="8px"
                  borderWidth={1}
                  borderColor="gray.lighter"
                  borderRadius="16px"
                  bg="white"
                  shadow="lg"
                  cursor="pointer"
                  transition="0.2s"
                  _hover={{
                    transform: 'translate(1px, -6px)',
                    shadow: 'dark-lg',
                  }}
                  onClick={() => props.onSelect(option)}
                  data-testid={`desktop-row-box-${index}`}
                >
                  {/* 色付きの領域 */}
                  <Center w="100%" h="100%" py="50%" borderRadius="12px" bg={optionBgColors[index]}>
                    <VStack h="100%" justify="space-between">
                      {/* 選択肢テキスト */}
                      <Text fontSize="22px" fontWeight="bold">
                        {option.value}
                      </Text>

                      {/* タッチアイコン */}
                      <Circle bg="white" shadow="md" size="48px">
                        <TouchIcon boxSize="32px" />
                      </Circle>
                    </VStack>
                  </Center>
                </Box>
              </SlideIn>
            </Box>
          ))}
        </HStack>
      ) : (
        // モバイル画面、または縦並び設定のとき
        <VStack spacing={'4%'} w="100%" h={`${30 + options.length * 12}%`} flexGrow={1} data-testid="mobile-display">
          {/* ボタン */}
          {options.map((option, index) => (
            <Box h="30%" minH={16} w="100%" key={option.id}>
              <SlideIn direction="left">
                {/*  外枠、白背景の領域 */}
                <Box
                  as="button"
                  w="100%"
                  h="100%"
                  minH="inherit"
                  p={['2px', '4px', '8px']}
                  borderWidth={1}
                  borderColor="gray.lighter"
                  borderRadius="16px"
                  bg="white"
                  shadow="lg"
                  cursor="pointer"
                  transition="transform 0.2s"
                  _hover={{
                    transform: 'translate(1px, -3px)',
                    shadow: 'dark-lg',
                  }}
                  onClick={() => props.onSelect(option)}
                  data-testid={`mobile-box-${index}`}
                >
                  {/* 色付きの領域 */}
                  <Center w="100%" h="100%" px="7%" borderRadius="12px" bg={optionBgColors[index]}>
                    <HStack w="100%" justify="space-between">
                      {/* 選択肢テキスト */}
                      <Text m={0} fontSize={['13px', '18px', '23px']} fontWeight="bold">
                        {option.value}
                      </Text>

                      {/* タッチアイコン */}
                      <Circle bg="white" shadow="md" size={['24px', '36px', '48px']}>
                        <TouchIcon boxSize="60%" />
                      </Circle>
                    </HStack>
                  </Center>
                </Box>
              </SlideIn>
            </Box>
          ))}
        </VStack>
      )}
    </VStack>
  )
}

export default SelectionSlidePlayerPresentation
