export class NotAuthenticatedError extends Error {
  constructor(message = 'you are not authenticated') {
    super(message)
    this.name = 'UnauthenticatedError'
  }
}

export class ApiError extends Error {
  constructor(public response: Response) {
    super(`API呼び出しエラー`)
    this.name = 'ApiError'
  }
}
