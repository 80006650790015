import { BoxProps, Center, Spinner, SpinnerProps } from '@chakra-ui/react'
import { FC } from 'react'
import { omit } from 'remeda'

export type LoadingProps = BoxProps & {
  color?: SpinnerProps['color']
  size?: SpinnerProps['size']
}

const Loading: FC<LoadingProps> = (props) => (
  <Center boxSize="100%" {...omit(props, ['size', 'color'])}>
    <Spinner color={props.color ?? 'primary.dark'} size={props.size ?? 'xl'} role="alert" aria-busy />
  </Center>
)

export default Loading
