import { Box, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { assertNever } from '../commons/utils'
import { SlideType } from '../orval/loov'
import UnderConstructionIcon from './elements/icons/UnderConstruction'
import H1 from './elements/typography/H1'
import FormSlidePlayer from './FormSlidePlayer'
import useScenarioPlayer from './needsTests/ScenarioPlayer/useScenarioPlayer'
import SelectionSlidePlayer from './SelectionSlidePlayer'
import VideoSlidePlayer from './VideoSlidePlayer'

export type ScenarioPlayerPresentationProps = ReturnType<typeof useScenarioPlayer> & {
  VideoSlidePlayer: typeof VideoSlidePlayer
  SelectionSlidePlayer: typeof SelectionSlidePlayer
  FormSlidePlayer: typeof FormSlidePlayer
}

const ScenarioPlayerPresentation: FC<ScenarioPlayerPresentationProps> = (props) => {
  const currentSlide = props.currentSlide

  return (
    <Box key={currentSlide?.id} w="100%" h="100%">
      {!currentSlide ? (
        <VStack w="100%" h="100%" justify="center" data-testid="preparing-notice-box">
          <UnderConstructionIcon h="200px" w="200px" />
          <H1>ただいま準備中です。</H1>
        </VStack>
      ) : currentSlide.type === SlideType.VIDEO ? (
        <props.VideoSlidePlayer slide={currentSlide} />
      ) : currentSlide.type === SlideType.SELECTION ? (
        <props.SelectionSlidePlayer
          publishedSelectionSlide={currentSlide}
          onMount={() => props.onViewStart()}
          onSelect={(option) => {
            props.onAnswerSelection(currentSlide, option)
            props.goNext(option)
          }}
        />
      ) : currentSlide.type === SlideType.FORM ? (
        <props.FormSlidePlayer
          publishedFormSlide={currentSlide}
          onMount={() => props.onViewStart()}
          onSubmit={async (data) => {
            props.onAnswerForm(currentSlide, data)
            props.goNext(currentSlide.publishedFormSlideContent)
          }}
        />
      ) : (
        assertNever(currentSlide)
      )}
    </Box>
  )
}

export default ScenarioPlayerPresentation
