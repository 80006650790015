import { Text, TextProps } from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'

const Body: ForwardRefRenderFunction<HTMLParagraphElement, TextProps> = ({ children, ...props }, ref) => {
  return (
    <Text fontSize="0.8125rem" fontWeight="medium" lineHeight="140%" letterSpacing="0.52px" {...props} ref={ref}>
      {children}
    </Text>
  )
}

export default forwardRef(Body)
