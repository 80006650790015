import {
  Box,
  chakra,
  Fade,
  FadeProps,
  Flex,
  Grid,
  Icon,
  IconButton,
  IconButtonProps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Duration } from 'luxon'
import {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  PointerEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IconType } from 'react-icons'
import { FaPause, FaPlay } from 'react-icons/fa6'
import { IoVolumeHigh, IoVolumeLow, IoVolumeMute } from 'react-icons/io5'
import { MdSlowMotionVideo } from 'react-icons/md'
import { clamp, isPlainObject, pipe } from 'remeda'
import useHover from '../../hooks/useHover'
import useIsMobile from '../../hooks/useIsMobile'
import { useVideoMetadata } from '../../hooks/useVideoMetadata'
import { VideoCaptionSegment } from '../../orval/loov'
import { VideoPlayerProps } from './VideoPlayer'

export type VideoPlayerPresentationProps = VideoPlayerProps

const VideoPlayerPresentation: FC<VideoPlayerPresentationProps> = ({
  videoProps: { autoPlay, onAutoPlayFailed, ...videoProps },
  caption = [],
}) => {
  const isMobile = useIsMobile()
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null)
  const Controls = useMemo(() => (isMobile ? MobileVideoControls : DesktopVideoControls), [isMobile])

  return (
    <Box
      tabIndex={-1}
      pos="relative"
      boxSize="full"
      // エンドユーザーに見られる部分なので、一般的なフォントで表示
      fontFamily="Roboto, Noto Sans JP"
      sx={{ touchAction: 'none' }}
    >
      <chakra.video
        {...videoProps}
        ref={(node) => {
          setVideoElement(node)

          const parentRef = videoProps.ref
          if (typeof parentRef === 'function') {
            parentRef(node)
          } else if (isPlainObject(parentRef) && 'current' in parentRef) {
            parentRef['current'] = node
          }
        }}
      />

      {/* コントロール + 字幕の表示領域 */}
      {videoElement && <Controls {...{ videoElement, caption, autoPlay, onAutoPlayFailed }} />}
    </Box>
  )
}

/**
 * デスクトップ端末用のコントロール
 */
const DesktopVideoControls: FC<{
  videoElement: HTMLVideoElement
  autoPlay?: boolean
  onAutoPlayFailed?: () => void
  caption: VideoCaptionSegment[]
}> = ({ videoElement, autoPlay = false, onAutoPlayFailed, caption }) => {
  const {
    isPaused = false,
    currentTime = 0,
    duration = 1,
    isMuted,
    playbackRate,
    volume = 1,
  } = useVideoMetadata(videoElement)
  const [isSeeking, setIsSeeking] = useState(false)
  const [playBar, setPlayBar] = useState<HTMLDivElement | null>(null)
  const { ref: wrapperRef, isHovered: isWrapperHovered } = useHover<HTMLDivElement>()
  const recentClickRef = useRef({
    left: { numRepeatedClick: 0, clickedAt: -Infinity },
    right: { numRepeatedClick: 0, clickedAt: -Infinity },
  })

  // 停止中、またはホバー中はコントロールを表示する
  const controlsFadeProps: FadeProps = useMemo(
    () => ({ in: isPaused || isWrapperHovered, unmountOnExit: true }),
    [isPaused, isWrapperHovered],
  )

  const currentTimeFormatted = useMemo(() => formatTime(currentTime), [currentTime])
  const durationFormatted = useMemo(() => formatTime(duration), [duration])
  // 現在の再生時間に対応する字幕セグメント
  const currentCaptionSegment = useMemo(
    () =>
      pipe(
        currentTime,
        (seconds) => Duration.fromObject({ seconds }).as('milliseconds'), // s -> ms
        (currentTime) => caption.find((segment) => segment.start <= currentTime && currentTime <= segment.end),
      ),
    [currentTime, caption],
  )

  // マウスの位置に応じて再生時間を更新する
  // 主に再生バーのシーク時に使用
  const updateCurrenTimeByPinterPosition = useCallback(
    (e: PointerEvent) => {
      if (!playBar) return

      const totalLength = playBar.clientWidth
      const mouseOffset = clamp(e.clientX - playBar.getBoundingClientRect().left, { min: 0, max: totalLength })
      videoElement.currentTime = (mouseOffset / totalLength) * duration
    },
    [duration, videoElement, playBar],
  )

  const pointerDownHandler: PointerEventHandler = useCallback(
    (e) => {
      // クリックされた時刻
      const clickedAt = new Date().getTime()

      // 画面の左右をダブルクリックしていた場合は、前後に5秒スキップする
      const { left, right } = videoElement.getBoundingClientRect()
      const center = left + (right - left) / 2
      const isLeftSideClicked = e.clientX < center
      const [recentClick, timeGap] = isLeftSideClicked
        ? [recentClickRef.current.left, -5]
        : [recentClickRef.current.right, 5]

      // ダブルクリックの場合、時間を調整する
      const isRepeatedClick = clickedAt - recentClick.clickedAt < 300
      if (isRepeatedClick) {
        videoElement.currentTime += timeGap
        recentClick.numRepeatedClick += 1
      } else {
        recentClick.numRepeatedClick = 1
      }
      recentClick.clickedAt = clickedAt

      // 連続スキップである場合を除き、再生/停止を切り替える
      if (recentClick.numRepeatedClick <= 2) {
        videoElement.paused ? videoElement.play() : videoElement.pause()
      }
    },
    [videoElement],
  )

  // キーボード操作時の処理
  const keyDownHandler: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === ' ') {
        videoElement.paused ? videoElement.play() : videoElement.pause()
      } else if (e.key === 'ArrowLeft') {
        videoElement.currentTime -= 5
      } else if (e.key === 'ArrowRight') {
        videoElement.currentTime += 5
      }
    },
    [videoElement],
  )

  // シークキャンセルイベント処理
  useEffect(() => {
    if (!isSeeking) return

    const cancelSeeking = () => {
      videoElement.play()
      setIsSeeking(false)
    }

    window.addEventListener('pointermove', updateCurrenTimeByPinterPosition)
    window.addEventListener('pointerup', cancelSeeking)
    window.addEventListener('pointerleave', cancelSeeking)
    return () => {
      window.removeEventListener('pointermove', updateCurrenTimeByPinterPosition)
      window.removeEventListener('pointerup', cancelSeeking)
      window.removeEventListener('pointerleave', cancelSeeking)
    }
  }, [videoElement, isSeeking, updateCurrenTimeByPinterPosition])

  useEffect(() => {
    if (!autoPlay) return

    const handler = () => {
      videoElement.play().catch((e) => {
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          return onAutoPlayFailed?.()
        }
        throw e
      })
    }

    videoElement.addEventListener('loadedmetadata', handler, { once: true })
    return () => videoElement.removeEventListener('loadedmetadata', handler)
  }, [autoPlay, videoElement, onAutoPlayFailed])

  return (
    // 動画再生領域全体を覆うラッパー
    <Box
      pos="absolute"
      top={0}
      boxSize="full"
      color="white"
      // エンドユーザーに見られる部分なので、一般的なフォントで表示
      fontFamily="Roboto, Noto Sans JP"
      sx={{ containerType: 'size' }}
      onPointerDown={pointerDownHandler}
      onKeyDown={keyDownHandler}
      ref={wrapperRef}
    >
      {/* バックドロップ */}
      <Fade {...controlsFadeProps}>
        <Box
          pos="absolute"
          bottom="0px"
          w="full"
          h="15cqh"
          bgGradient="linear(to-b, transparent, blackAlpha.500, blackAlpha.800)"
        />
      </Fade>

      {/* コントロール + 字幕 */}
      <Flex pos="absolute" bottom="0" direction="column" w="full">
        {/* 字幕表示 */}
        {currentCaptionSegment && (
          <VStack spacing="0px" mb="1.3cqh">
            {currentCaptionSegment.text.split('\n').map((line, i) => (
              <Text
                key={i}
                px="1.1cqh"
                w="fit-content"
                maxW="full"
                bg="blackAlpha.700"
                fontSize="4cqh"
                textAlign="center"
              >
                {line}
              </Text>
            ))}
          </VStack>
        )}

        {/* コントロール表示 */}
        <Fade {...controlsFadeProps}>
          <Stack
            spacing={0}
            justify="end"
            w="full"
            px="2cqw"
            pb="1cqh"
            fontSize="calc(8px + 0.6cqw)"
            userSelect="none"
            onPointerDown={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {/* 再生バー */}
            {/* ホバー可能領域を広げるための wrapper */}
            <Flex
              direction="column"
              justify="end"
              h="20px"
              role="group"
              cursor="pointer"
              onPointerDown={(e) => {
                videoElement.pause()
                setIsSeeking(true)
                updateCurrenTimeByPinterPosition(e.nativeEvent)
              }}
            >
              {/* 再生バーが上下に広がるための隙間を確保するラッパー */}
              <Flex direction="column" justify="center" h="5px">
                {/* 再生バー本体 */}
                <Box
                  h={isSeeking ? '100%' : '50%'}
                  bg="whiteAlpha.500"
                  w="full"
                  _groupHover={{ h: '100%' }}
                  transition="height 0.2s"
                  ref={setPlayBar}
                >
                  {/* 再生済み区間 */}
                  <Box pos="relative" h="full" bg="red" w={`${(currentTime / duration) * 100}%`}>
                    {/* つまみ */}
                    <Box
                      pos="absolute"
                      top="50%"
                      right={0}
                      transform="translate(50%, -50%)"
                      boxSize="12px"
                      rounded="full"
                      bg="red"
                      opacity={isSeeking ? 1 : 0}
                      _groupHover={{ opacity: 1 }}
                      transition="opacity 0.2s"
                    />
                  </Box>
                </Box>
              </Flex>
            </Flex>

            {/* コントロールボタン */}
            <Flex w="full" h="8cqh">
              {/* 再生/停止ボタン */}
              <ControlsIconButton
                {...(isPaused
                  ? { iconAs: FaPlay, onPointerDown: () => videoElement?.play(), 'aria-label': 'play' }
                  : { iconAs: FaPause, onPointerDown: () => videoElement?.pause(), 'aria-label': 'pause' })}
              />

              {/* 音量コントロール */}
              <Flex h="full" role="group" display={['none', 'flex']}>
                {/* ボリュームボタン */}
                <ControlsIconButton
                  iconAs={isMuted || volume === 0 ? IoVolumeMute : volume < 0.5 ? IoVolumeLow : IoVolumeHigh}
                  aria-label="pause"
                  onClick={() => (videoElement.muted = !isMuted)}
                />

                {/* ボリュームバー */}
                <Grid
                  placeItems="center"
                  w="0cqw"
                  opacity={0}
                  _groupHover={{ w: '8cqw', opacity: 1 }}
                  transition="all 0.12s"
                >
                  <Slider
                    min={0}
                    max={1}
                    step={0.01}
                    aria-label="volume slider"
                    value={isMuted ? 0 : volume}
                    onChange={(value) => (videoElement.volume = value)}
                  >
                    <SliderTrack>
                      <SliderFilledTrack bg="white" />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Grid>
              </Flex>

              {/* 再生時間のテキスト表示 */}
              <Grid placeItems="center" h="full" px={4}>
                <Text>{`${currentTimeFormatted} / ${durationFormatted}`}</Text>
              </Grid>

              <Spacer />

              {/* 再生速度変更ボタン */}
              <Flex
                h="full"
                tabIndex={-1}
                role="group"
                cursor="pointer"
                onPointerDown={() => {
                  const newRate = videoElement.playbackRate + 0.25
                  videoElement.playbackRate = newRate <= 2 ? newRate : 1
                }}
              >
                <ControlsIconButton iconAs={MdSlowMotionVideo} aria-label="change playback rate" />

                <Flex
                  alignItems="center"
                  w="0px"
                  _groupHover={{ w: '40px' }}
                  transition="width 0.12s"
                  overflow="hidden"
                  sx={{ textWrap: 'nowrap' }}
                >
                  <Text>{`x ${playbackRate?.toFixed(2).replace(/0$/, '')}`}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Stack>
        </Fade>
      </Flex>
    </Box>
  )
}

/**
 * モバイル端末用のコントロール
 */
const MobileVideoControls: FC<{
  videoElement: HTMLVideoElement
  autoPlay?: boolean
  onAutoPlayFailed?: () => void
  caption: VideoCaptionSegment[]
}> = ({ videoElement, autoPlay = false, onAutoPlayFailed, caption }) => {
  // 自動再生が有効の場合、初期状態ではコントロールを非表示にする
  const [isControlsVisible, setIsControlsVisible] = useState(!autoPlay)
  const [isSeeking, setIsSeeking] = useState(false)
  const [playBar, setPlayBar] = useState<HTMLDivElement | null>(null)
  const { isPaused = true, currentTime = 0, duration = 1, playbackRate } = useVideoMetadata(videoElement)
  const hideControlsTimerRef = useRef(0)
  const recentClickRef = useRef({
    left: { clickedAt: -Infinity, numRepeatedClick: 0 },
    right: { clickedAt: -Infinity, numRepeatedClick: 0 },
  })

  // 再生時間表示用のフォーマット
  const currentTimeFormatted = useMemo(() => formatTime(currentTime), [currentTime])
  const durationFormatted = useMemo(() => formatTime(duration), [duration])
  // 現在の再生時間に対応する字幕セグメント
  const currentCaptionSegment = useMemo(
    () =>
      pipe(
        currentTime,
        (seconds) => Duration.fromObject({ seconds }).as('milliseconds'), // s -> ms
        (currentTime) => caption.find((segment) => segment.start <= currentTime && currentTime <= segment.end),
      ),
    [currentTime, caption],
  )

  // マウスの位置に応じて再生時間を更新する
  // 主に再生バーのシーク時に使用
  const updateCurrenTimeByPinterPosition = useCallback(
    (e: PointerEvent) => {
      if (!playBar) return

      const totalLength = playBar.clientWidth
      const mouseOffset = clamp(e.clientX - playBar.getBoundingClientRect().left, { min: 0, max: totalLength })
      videoElement.currentTime = (mouseOffset / totalLength) * duration
    },
    [duration, videoElement, playBar],
  )

  // 2s 後にコントロールを非表示にするタイマーを再設定する
  const resetHideControlsTimer = useCallback(() => {
    window.clearTimeout(hideControlsTimerRef.current)
    hideControlsTimerRef.current = window.setTimeout(() => !videoElement.paused && setIsControlsVisible(false), 2500)
  }, [videoElement])

  // シーク開始
  const startSeeking = useCallback(() => {
    videoElement.pause()
    setIsSeeking(true)
  }, [videoElement])

  // シーク終了
  const endSeeking = useCallback(() => {
    videoElement.play()
    setIsSeeking(false)
  }, [videoElement])

  const wrapperOnClickHandler: MouseEventHandler = useCallback(
    (e) => {
      resetHideControlsTimer()

      // クリックされた時刻
      const clickedAt = new Date().getTime()

      // 画面の左右をダブルクリックしていた場合は、前後に5秒スキップする
      const { left, right } = videoElement.getBoundingClientRect()
      const center = left + (right - left) / 2
      const isLeftSideClicked = e.clientX < center
      const [recentClick, timeGap] = isLeftSideClicked
        ? [recentClickRef.current.left, -5]
        : [recentClickRef.current.right, 5]

      // ダブルクリックの場合、時間を調整する
      const isRepeatedClick = clickedAt - recentClick.clickedAt < 300
      if (isRepeatedClick) {
        videoElement.currentTime += timeGap
        recentClick.numRepeatedClick += 1
      } else {
        recentClick.numRepeatedClick = 0
      }
      recentClick.clickedAt = clickedAt

      // 連続スキップである場合を除き、コントロールの表示を切り替える
      if (recentClick.numRepeatedClick <= 1) {
        setIsControlsVisible((prev) => !prev)
      }
    },
    [videoElement, resetHideControlsTimer],
  )

  // シーク開始時に、シークキャンセルイベントを設定する
  useEffect(() => {
    if (!isSeeking) return

    window.addEventListener('pointermove', updateCurrenTimeByPinterPosition)
    window.addEventListener('pointerup', endSeeking)
    window.addEventListener('pointerleave', endSeeking)
    return () => {
      window.removeEventListener('pointermove', updateCurrenTimeByPinterPosition)
      window.removeEventListener('pointerup', endSeeking)
      window.removeEventListener('pointerleave', endSeeking)
    }
  }, [isSeeking, endSeeking, updateCurrenTimeByPinterPosition])

  useEffect(() => {
    if (!autoPlay) return

    const handler = () => {
      videoElement.play().catch((e) => {
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          onAutoPlayFailed?.()
          return setIsControlsVisible(true)
        }
        throw e
      })
    }

    videoElement.addEventListener('loadedmetadata', handler, { once: true })
    return () => videoElement.removeEventListener('loadedmetadata', handler)
  }, [autoPlay, videoElement, onAutoPlayFailed])

  return (
    // 動画再生領域全体を覆うラッパー
    <Box
      pos="absolute"
      top={0}
      boxSize="full"
      color="white"
      sx={{ touchAction: 'none' }}
      onClick={wrapperOnClickHandler}
    >
      {/* 字幕表示 */}
      <Flex pos="absolute" bottom="20px" direction="column" align="center" w="full">
        {currentCaptionSegment?.text.split('\n').map((line, i) => (
          <Text
            key={i}
            px="6px"
            py="2px"
            w="fit-content"
            maxW="full"
            bg="blackAlpha.700"
            fontSize="16px"
            textAlign="center"
          >
            {line}
          </Text>
        ))}
      </Flex>

      <Fade in={isControlsVisible} unmountOnExit transition={{ exit: { duration: 0.6 } }} style={{ height: '100%' }}>
        {/* バックドロップ */}
        <Box pos="absolute" top="0" boxSize="full" bg="blackAlpha.400" />

        {/* コントロール */}
        <Box
          boxSize="full"
          onClick={(e) => e.stopPropagation()}
          // コントロールが操作されたら非表示タイマーをリセットする
          onPointerDown={(e) => {
            resetHideControlsTimer()
            e.stopPropagation()
          }}
        >
          {/* 再生/停止ボタン */}
          <IconButton
            variant="unstyled"
            pos="absolute"
            inset="0 0 0 0"
            m="auto"
            h="25%"
            aspectRatio="1"
            bg="blackAlpha.600"
            lineHeight={0}
            {...(isPaused
              ? {
                  icon: <Icon as={FaPlay} boxSize="60%" transform="translate(8%)" />,
                  onClick: () => videoElement.play(),
                  'aria-label': 'play',
                }
              : {
                  icon: <Icon as={FaPause} boxSize="60%" />,
                  onClick: () => videoElement.pause(),
                  'aria-label': 'pause',
                })}
          />

          {/* 下部コントロール */}
          <Stack spacing={0} pos="absolute" bottom={0} w="full" px="16px">
            <Flex justify="space-between" align="end" fontSize="12px">
              {/* 再生時間表示 */}
              <Text>
                <span style={{ fontWeight: 'bold' }}>{currentTimeFormatted}</span> / {durationFormatted}
              </Text>

              {/* 再生速度コントロール */}
              <Flex
                h="full"
                tabIndex={-1}
                role="group"
                cursor="pointer"
                onClick={() => {
                  const newRate = videoElement.playbackRate + 0.25
                  videoElement.playbackRate = newRate <= 2 ? newRate : 1
                }}
              >
                <IconButton
                  icon={<Icon as={MdSlowMotionVideo} boxSize="100%" />}
                  variant="unstyled"
                  boxSize="20px"
                  minW={0}
                  lineHeight={0}
                  aria-label="change playback rate"
                />

                <Flex
                  alignItems="center"
                  w="0px"
                  _groupHover={{ w: '34px' }}
                  transition="width 0.12s"
                  overflow="hidden"
                  sx={{ textWrap: 'nowrap' }}
                >
                  <Text>{`x ${playbackRate?.toFixed(2).replace(/0$/, '')}`}</Text>
                </Flex>
              </Flex>
            </Flex>

            {/* 再生バーのタッチ可能領域を広げるための wrapper */}
            <Flex
              direction="column"
              justify="center"
              h="30px"
              w="full"
              cursor="pointer"
              onPointerDown={(e) => {
                startSeeking()
                updateCurrenTimeByPinterPosition(e.nativeEvent)
              }}
            >
              {/* 再生バー */}
              <Box h="2px" w="full" bg="whiteAlpha.500" ref={setPlayBar}>
                {/* 再生済み区間 */}
                <Box pos="relative" h="full" bg="red" w={`${(currentTime / duration) * 100}%`}>
                  {/* つまみ */}
                  <Box
                    pos="absolute"
                    top="50%"
                    right={0}
                    transform="translate(50%, -50%)"
                    boxSize="12px"
                    rounded="full"
                    bg="red"
                  />
                </Box>
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Fade>
    </Box>
  )
}

const ControlsIconButton: FC<{ iconAs: IconType } & IconButtonProps> = ({ iconAs, ...iconButtonProps }) => (
  <IconButton
    variant="unstyled"
    minW={0}
    h="full"
    aspectRatio="1"
    lineHeight={0}
    icon={<Icon as={iconAs} boxSize="60%" />}
    {...iconButtonProps}
  />
)

function formatTime(seconds: number): string {
  const d = Duration.fromObject({ seconds: Math.floor(seconds) })

  return d.toFormat(d.as('hours') < 1 ? 'mm:ss' : 'hh:mm:ss')
}

export default VideoPlayerPresentation
