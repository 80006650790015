import { FC } from 'react'
import { getScenarioMobileThumbnail } from '../orval/loovPublic'
import EmbeddedMobileCustomFloatPresentation from './EmbeddedMobileCustomFloatPresentation'
import VisualMedia from './VisualMedia'

export type EmbeddedMobileCustomFloatProps = {
  scenarioMobileThumbnailId: string
  position: 'top_left' | 'top_right' | 'side_left' | 'side_right' | 'bottom_left' | 'bottom_right'
  onClick: () => void
  onClose: () => void
}

const EmbeddedMobileCustomFloat: FC<EmbeddedMobileCustomFloatProps> = (props) => {
  return (
    <EmbeddedMobileCustomFloatPresentation
      {...props}
      getScenarioMobileThumbnail={getScenarioMobileThumbnail}
      VisualMedia={VisualMedia}
    />
  )
}

export default EmbeddedMobileCustomFloat
