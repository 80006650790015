import { useContext } from 'react'
import { ScenarioPlayerContext } from './ScenarioPlayerProvider'

export default function useScenarioPlayer() {
  const context = useContext(ScenarioPlayerContext)

  if (!context) {
    throw Error('ScenarioPlayerContext が取得できませんでした。 ScenarioPlayerProvider を忘れていませんか？')
  }

  return context
}
