import { chakra } from '@chakra-ui/react'
import { ComponentProps, FC } from 'react'
import { VideoCaptionSegment } from '../../orval/loov'
import VideoPlayerPresentation from './VideoPlayerPresentation'

export type VideoPlayerProps = {
  videoProps: ComponentProps<typeof chakra.video> & { onAutoPlayFailed?: () => void }
  caption?: VideoCaptionSegment[]
}

const VideoPlayer: FC<VideoPlayerProps> = (props) => {
  return <VideoPlayerPresentation {...props} />
}

export default VideoPlayer
