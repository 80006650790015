import { Duration } from 'luxon'
import { MobileDisplayType } from '../orval/loov'

export function ensure<T>(arg: T, error?: string | Error): NonNullable<T> {
  if (arg === null || arg === undefined) {
    if (error instanceof Error) {
      throw error
    }

    throw Error(error ?? 'Please ensure an argument is neither undefined nor null.')
  }

  return arg
}

export function assertNever(_: never): never {
  throw Error('Exhaustiveness Error')
}

export function downloadBlob(blob: Blob, filename: string): void {
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = filename
  anchor.click()
  URL.revokeObjectURL(anchor.href)
}

export async function downloadUrl(url: string, filename: string): Promise<void> {
  const res = await fetch(url)
  downloadBlob(await res.blob(), filename)
}

export async function noop() {
  // do nothing
}

export function convertVideoCaptionToVttUrl(caption: { start: number; end: number; text: string }[]): string {
  const vtt = [
    `WEBVTT`,
    ...caption.map(
      (c) =>
        `${Duration.fromMillis(c.start).toFormat(`hh:mm:ss.SSS`)}` +
        ` --> ` +
        `${Duration.fromMillis(c.end).toFormat(`hh:mm:ss.SSS`)}` +
        `\n` +
        `${c.text}`,
    ),
  ].join(`\n\n`)
  return URL.createObjectURL(new Blob([vtt], { type: 'text/vtt' }))
}

export type CustomMobileDisplayType =
  | typeof MobileDisplayType.CUSTOM_TOP_LEFT
  | typeof MobileDisplayType.CUSTOM_TOP_RIGHT
  | typeof MobileDisplayType.CUSTOM_SIDE_LEFT
  | typeof MobileDisplayType.CUSTOM_SIDE_RIGHT
  | typeof MobileDisplayType.CUSTOM_BOTTOM_LEFT
  | typeof MobileDisplayType.CUSTOM_BOTTOM_RIGHT

export function isMobileDisplayTypeCustom(
  mobileDisplayType: MobileDisplayType,
): mobileDisplayType is CustomMobileDisplayType {
  return [
    MobileDisplayType.CUSTOM_TOP_LEFT,
    MobileDisplayType.CUSTOM_TOP_RIGHT,
    MobileDisplayType.CUSTOM_SIDE_LEFT,
    MobileDisplayType.CUSTOM_SIDE_RIGHT,
    MobileDisplayType.CUSTOM_BOTTOM_LEFT,
    MobileDisplayType.CUSTOM_BOTTOM_RIGHT,
  ].includes(mobileDisplayType)
}
