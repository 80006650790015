import { chakra, Image } from '@chakra-ui/react'
import { ComponentProps, FC } from 'react'
import VisualMediaPresentation from './VisualMediaPresentation'

export type VisualMediaProps = {
  commonProps?: ComponentProps<typeof Image> & ComponentProps<typeof chakra.video>
  imageProps?: ComponentProps<typeof Image>
  videoProps?: ComponentProps<typeof chakra.video>
}

const VisualMedia: FC<VisualMediaProps> = (props) => {
  return <VisualMediaPresentation {...props} Image={Image} createElement={document.createElement.bind(document)} />
}

export default VisualMedia
