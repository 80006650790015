import { Box, Fade, useBoolean } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { assertNever, CustomMobileDisplayType, isMobileDisplayTypeCustom } from '../commons/utils'
import useIsMobile from '../hooks/useIsMobile'
import { DesktopDisplayType, MobileDisplayType } from '../orval/loov'
import {
  DesktopDisplayHorizontalOffsetType,
  DesktopDisplayVerticalOffsetType,
  PublishedScenarioDetail,
} from '../orval/loovPublic'
import EmbeddedMobileCustomFloat, { EmbeddedMobileCustomFloatProps } from './EmbeddedMobileCustomFloat'
import EmbeddedDesktopFloat from './needsTests/ScenarioPlayer/EmbeddedDesktopFloat'
import EmbeddedMobileBottomFloat, {
  EmbeddedMobileBottomFloatProps,
} from './needsTests/ScenarioPlayer/EmbeddedMobileBottomFloat'
import EmbeddedMobileSideFloat, {
  EmbeddedMobileSideFloatProps,
} from './needsTests/ScenarioPlayer/EmbeddedMobileSideFloat'
import ScenarioPlayerModal from './needsTests/ScenarioPlayer/ScenarioPlayerModal'
import ScenarioPlayerProvider from './needsTests/ScenarioPlayer/ScenarioPlayerProvider'

export type EmbeddedFloatingScenarioPlayerPresentationProps = {
  publishedScenario: PublishedScenarioDetail
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  mobileDisplayType: MobileDisplayType
  scenarioMobileThumbnailId: string | null
  preview?: boolean
  useIsMobile: typeof useIsMobile
  EmbeddedDesktopFloat: typeof EmbeddedDesktopFloat
  EmbeddedMobileBottomFloat: typeof EmbeddedMobileBottomFloat
  EmbeddedMobileSideFloat: typeof EmbeddedMobileSideFloat
  EmbeddedMobileCustomFloat: typeof EmbeddedMobileCustomFloat
  ScenarioPlayerProvider: typeof ScenarioPlayerProvider
  ScenarioPlayerModal: typeof ScenarioPlayerModal
}

const EmbeddedFloatingScenarioPlayerPresentation: FC<EmbeddedFloatingScenarioPlayerPresentationProps> = (props) => {
  const isMobile = props.useIsMobile()
  const hasThumbnail = !isMobile || isMobileDisplayTypeBottom(props.mobileDisplayType)
  const [mode, setMode] = useState<'hidden' | 'default' | 'playing'>('default')
  const [isReady, setIsReady] = useBoolean(!hasThumbnail)
  const containerRef = useRef<HTMLDivElement>(null)

  return mode === 'hidden' ? null : (
    <>
      {mode === 'default' && (
        <Box id="loov-float" pos="relative" zIndex={1e9 - 1} data-testid="float-box">
          <Fade in={isReady} transition={{ enter: { duration: 0.15 } }}>
            {!isMobile ? (
              <props.EmbeddedDesktopFloat
                displayType={props.desktopDisplayType}
                verticalOffset={props.desktopDisplayVerticalOffset}
                verticalOffsetType={props.desktopDisplayVerticalOffsetType}
                horizontalOffset={props.desktopDisplayHorizontalOffset}
                horizontalOffsetType={props.desktopDisplayHorizontalOffsetType}
                description={props.publishedScenario.modalDescription}
                thumbnailSrc={props.publishedScenario.scenarioThumbnail?.url}
                showError={props.preview}
                onReady={setIsReady.on}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeSide(props.mobileDisplayType) ? (
              <props.EmbeddedMobileSideFloat
                position={mapSideMobileDisplayTypeToPosition(props.mobileDisplayType)}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeBottom(props.mobileDisplayType) ? (
              <props.EmbeddedMobileBottomFloat
                position={mapBottomMobileDisplayTypeToPosition(props.mobileDisplayType)}
                description={props.publishedScenario.modalDescription}
                thumbnailSrc={props.publishedScenario.scenarioThumbnail?.url}
                showError={props.preview}
                onReady={setIsReady.on}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeCustom(props.mobileDisplayType) ? (
              props.scenarioMobileThumbnailId && (
                <props.EmbeddedMobileCustomFloat
                  position={mapCustomMobileDisplayTypeToPosition(props.mobileDisplayType)}
                  scenarioMobileThumbnailId={props.scenarioMobileThumbnailId}
                  onClick={() => setMode('playing')}
                  onClose={() => setMode('hidden')}
                />
              )
            ) : props.mobileDisplayType === MobileDisplayType.NONE ? null : (
              assertNever(props.mobileDisplayType)
            )}
          </Fade>
        </Box>
      )}

      {/* 再生画面 */}
      {isReady && (
        <Box id="loov-float-modal" pos="relative" zIndex={1e9} ref={containerRef} data-testid="playback-box">
          <props.ScenarioPlayerProvider publishedScenario={props.publishedScenario} preview={props.preview}>
            <props.ScenarioPlayerModal
              isOpen={mode === 'playing'}
              onClose={() => setMode('default')}
              containerRef={containerRef}
            />
          </props.ScenarioPlayerProvider>
        </Box>
      )}
    </>
  )
}

export default EmbeddedFloatingScenarioPlayerPresentation

type SideMobileDisplayType = typeof MobileDisplayType.SIDE_LEFT | typeof MobileDisplayType.SIDE_RIGHT
type BottomMobileDisplayType = typeof MobileDisplayType.BOTTOM_RIGHT | typeof MobileDisplayType.BOTTOM_LEFT

function isMobileDisplayTypeSide(mobileDisplayType: MobileDisplayType): mobileDisplayType is SideMobileDisplayType {
  return [MobileDisplayType.SIDE_RIGHT, MobileDisplayType.SIDE_LEFT].includes(mobileDisplayType)
}

function isMobileDisplayTypeBottom(mobileDisplayType: MobileDisplayType): mobileDisplayType is BottomMobileDisplayType {
  return [MobileDisplayType.BOTTOM_RIGHT, MobileDisplayType.BOTTOM_LEFT].includes(mobileDisplayType)
}

function mapSideMobileDisplayTypeToPosition(
  mobileDisplayType: SideMobileDisplayType,
): EmbeddedMobileSideFloatProps['position'] {
  switch (mobileDisplayType) {
    case MobileDisplayType.SIDE_LEFT:
      return 'left'
    case MobileDisplayType.SIDE_RIGHT:
      return 'right'
  }
}

function mapBottomMobileDisplayTypeToPosition(
  mobileDisplayType: BottomMobileDisplayType,
): EmbeddedMobileBottomFloatProps['position'] {
  switch (mobileDisplayType) {
    case MobileDisplayType.BOTTOM_LEFT:
      return 'left'
    case MobileDisplayType.BOTTOM_RIGHT:
      return 'right'
  }
}

function mapCustomMobileDisplayTypeToPosition(
  mobileDisplayType: CustomMobileDisplayType,
): EmbeddedMobileCustomFloatProps['position'] {
  switch (mobileDisplayType) {
    case MobileDisplayType.CUSTOM_TOP_LEFT:
      return 'top_left'
    case MobileDisplayType.CUSTOM_TOP_RIGHT:
      return 'top_right'
    case MobileDisplayType.CUSTOM_SIDE_LEFT:
      return 'side_left'
    case MobileDisplayType.CUSTOM_SIDE_RIGHT:
      return 'side_right'
    case MobileDisplayType.CUSTOM_BOTTOM_LEFT:
      return 'bottom_left'
    case MobileDisplayType.CUSTOM_BOTTOM_RIGHT:
      return 'bottom_right'
  }
}
