import { useEffect, useState } from 'react'

export function useVideoMetadata(video: HTMLVideoElement | null) {
  const [isPaused, setIsPaused] = useState<boolean>()
  const [currentTime, setCurrentTime] = useState<number>()
  const [internalDuration, setInternalDuration] = useState<number>()
  const [volume, setVolume] = useState<number>()
  const [isMuted, setIsMuted] = useState<boolean>()
  const [playbackRate, setPlaybackRate] = useState<number>()

  // 扱いやすくするため、外部向けには NaN や Infinity は返さない
  const duration = Number.isFinite(internalDuration) ? internalDuration : undefined

  // isPaused の更新
  useEffect(() => {
    setIsPaused(video?.paused)

    if (!video) return

    const onPause = () => setIsPaused(true)
    const onPlay = () => setIsPaused(false)
    video.addEventListener('pause', onPause)
    video.addEventListener('play', onPlay)
    return () => {
      video.removeEventListener('pause', onPause)
      video.removeEventListener('play', onPlay)
    }
  }, [video])

  // currentTime の更新
  useEffect(() => {
    setIsPaused(video?.paused)

    if (!video) return

    const onTimeUpdate = () => setCurrentTime(video.currentTime)
    video.addEventListener('timeupdate', onTimeUpdate)
    return () => video.removeEventListener('timeupdate', onTimeUpdate)
  }, [video])

  // duration の更新
  useEffect(() => {
    setInternalDuration(video?.duration)

    if (!video) return

    const onDurationChange = () => setInternalDuration(video.duration)
    video.addEventListener('durationchange', onDurationChange)
    return () => video.removeEventListener('durationchange', onDurationChange)
  }, [video])

  // Webm などの動画形式はメタデータに duration を持たないことがあり、duration の初期値が Infinity になる
  // その場合は currentTime を十分に大きくしてシークを行って duration を取得させる
  useEffect(() => {
    if (video && internalDuration === Infinity) {
      video.currentTime = 1e10
      // duration が取得できたら再生位置を先頭に戻す
      video.addEventListener('durationchange', () => (video.currentTime = 0), { once: true })
    }
  }, [video, internalDuration])

  // volume の更新
  useEffect(() => {
    setVolume(video?.volume)

    if (!video) return

    const onVolumeChange = () => setVolume(video.volume)
    video.addEventListener('volumechange', onVolumeChange)
    return () => video.removeEventListener('volumechange', onVolumeChange)
  }, [video])

  // isMuted の更新
  useEffect(() => {
    setIsMuted(video?.muted)

    if (!video) return

    const onVolumeChange = () => setIsMuted(video.muted)
    video.addEventListener('volumechange', onVolumeChange)
    return () => video.removeEventListener('volumechange', onVolumeChange)
  }, [video])

  // playbackRate の更新
  useEffect(() => {
    setPlaybackRate(video?.playbackRate)

    if (!video) return

    const onRateChange = () => setPlaybackRate(video.playbackRate)
    video.addEventListener('ratechange', onRateChange)
    return () => video.removeEventListener('ratechange', onRateChange)
  }, [video])

  return {
    isPaused,
    currentTime,
    duration,
    volume,
    isMuted,
    playbackRate,
  }
}
