import { Box } from '@chakra-ui/react'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

type SlideInProps = { direction: 'left' | 'up' }
const SlideIn: FC<PropsWithChildren<SlideInProps>> = ({ children, direction }) => {
  const [left, setLeft] = useState(direction === 'left' ? '100%' : undefined)
  const [top, setTop] = useState(direction === 'up' ? '100%' : undefined)

  useEffect(() => {
    if (direction === 'left') {
      setLeft('0%')
    } else if (direction === 'up') {
      setTop('0%')
    }
  }, [setLeft, setTop, direction])

  return (
    <Box w="100%" h="100%" minH="inherit" pos="relative">
      <Box
        w="100%"
        h="100%"
        minH="inherit"
        pos="absolute"
        left={left}
        top={top}
        transition="0.5s cubic-bezier(0, 0.25, 0.25, 1.0)"
      >
        {children}
      </Box>
    </Box>
  )
}

export default SlideIn
