import { Box, BoxProps, CloseButton, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { DesktopDisplayType } from '../../../orval/loov'
import { DesktopDisplayHorizontalOffsetType, DesktopDisplayVerticalOffsetType } from '../../../orval/loovPublic'
import { EmbeddedFloatThumbnail } from '../../EmbeddedFloatThumbnail'

type EmbeddedDesktopFloatProps = {
  displayType: DesktopDisplayType
  verticalOffset: number
  verticalOffsetType: DesktopDisplayVerticalOffsetType
  horizontalOffset: number
  horizontalOffsetType: DesktopDisplayHorizontalOffsetType
  description: string
  thumbnailSrc?: string
  showError?: boolean
  onReady: () => void
  onClick: () => void
  onClose: () => void
}

const EmbeddedDesktopFloat: FC<EmbeddedDesktopFloatProps> = (props) => {
  let offsetParams: BoxProps

  if (props.displayType === DesktopDisplayType.BOTTOM_RIGHT) {
    offsetParams = {
      bottom: '50px',
      right: '50px',
    }
  } else if (props.displayType === DesktopDisplayType.BOTTOM_LEFT) {
    offsetParams = {
      bottom: '50px',
      left: '50px',
    }
  } else {
    const vOffset = `${props.verticalOffset}px`
    const hOffset = `${props.horizontalOffset}px`

    offsetParams = {
      ...(props.verticalOffsetType === DesktopDisplayVerticalOffsetType.FROM_TOP
        ? { top: vOffset }
        : { bottom: vOffset }),
      ...(props.horizontalOffsetType === DesktopDisplayHorizontalOffsetType.FROM_LEFT
        ? { left: hOffset }
        : { right: hOffset }),
    }
  }
  return (
    <Box
      pos="fixed"
      w="280px"
      p="16px"
      top={offsetParams.top}
      bottom={offsetParams.bottom}
      left={offsetParams.left}
      right={offsetParams.right}
      borderRadius="12px"
      bg="rgba(243,243,243,0.9)"
      shadow="dark-lg"
      _hover={{
        opacity: 0.85,
        transform: 'translateY(-5px)',
      }}
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      onClick={() => props.onClick()}
    >
      <CloseButton
        pos="absolute"
        fontSize="12px"
        boxSize="24px"
        top="0"
        right="0"
        transform="translate(50%, -50%)"
        borderWidth={1}
        borderColor="black.lighter"
        borderRadius="50%"
        bg="background"
        _hover={{ bg: 'black.lighter' }}
        onClick={(e) => (e.stopPropagation(), props.onClose())}
      />

      {/* 説明文 */}
      <Text fontSize="15px" m={0} mb="8px" align="center">
        {props.description}
      </Text>
      {/* サムネイル */}

      <EmbeddedFloatThumbnail
        thumbnailSrc={props.thumbnailSrc}
        showError={props.showError}
        onReady={props.onReady}
        aspectRatio="16/9"
        minH={0}
        borderRadius={8}
        overflow="hidden"
      />
    </Box>
  )
}

export default EmbeddedDesktopFloat
