import { Flex } from '@chakra-ui/react'
import { ComponentProps, FC } from 'react'
import { EmbeddedFloatThumbnailPresentation } from './EmbeddedFloatThumbnailPresentation'
import VisualMedia from './VisualMedia'

export type EmbeddedFloatThumbnailProps = ComponentProps<typeof Flex> & {
  thumbnailSrc?: string
  onReady: () => void
  showError?: boolean
}

export const EmbeddedFloatThumbnail: FC<EmbeddedFloatThumbnailProps> = (props) => {
  return <EmbeddedFloatThumbnailPresentation {...props} VisualMedia={VisualMedia} />
}
