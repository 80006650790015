import { Button, HStack, Icon } from '@chakra-ui/react'
import { FC } from 'react'
import { BsArrowClockwise } from 'react-icons/bs'
import Body from '../../elements/typography/Body'
import useScenarioPlayer from './useScenarioPlayer'

const ScenarioPlayerGoBackToBeginningButton: FC = () => {
  const { goBackToBeginning } = useScenarioPlayer()

  return (
    <Button variant="link" borderRadius={0} onClick={() => goBackToBeginning()}>
      <HStack spacing={1} color="gray.basic">
        <Icon as={BsArrowClockwise} boxSize="16px" />
        <Body fontSize="13px" fontWeight="medium">{`最初から`}</Body>
      </HStack>
    </Button>
  )
}

export default ScenarioPlayerGoBackToBeginningButton
