import { Flex, Stack } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import Body from './elements/typography/Body'
import { EmbeddedFloatThumbnailProps } from './EmbeddedFloatThumbnail'
import VisualMedia from './VisualMedia'

export type EmbeddedFloatThumbnailPresentationProps = EmbeddedFloatThumbnailProps & {
  VisualMedia: typeof VisualMedia
}

export const EmbeddedFloatThumbnailPresentation: FC<EmbeddedFloatThumbnailPresentationProps> = ({
  thumbnailSrc,
  onReady,
  showError,
  VisualMedia,
  ...flexProps
}) => {
  useEffect(() => {
    if (!thumbnailSrc && showError) {
      onReady()
    }
  }, [thumbnailSrc, showError, onReady])
  return (
    <Flex {...flexProps}>
      {thumbnailSrc ? (
        <VisualMedia
          commonProps={{ src: thumbnailSrc, boxSize: 'full', objectFit: 'cover' }}
          imageProps={{ onLoad: () => onReady() }}
          videoProps={{ onLoadedMetadata: () => onReady() }}
        />
      ) : (
        showError && (
          <Stack color="alert.basic" data-testid={'errorMessage'}>
            <Body>サムネイル画像が設定されていません。</Body>
            <Body>このメッセージは、プレビュー以外では表示されません。</Body>
          </Stack>
        )
      )}
    </Flex>
  )
}
