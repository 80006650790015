import { InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider'
import { mapValues } from 'remeda'
import { cognitoClient } from '../commons/cognitoClient'
import { ApiError, NotAuthenticatedError } from '../commons/errors'
import { LocalStorageKey } from '../commons/localStorageKey'
import { ensure } from '../commons/utils'

// MEMO: ここで Throw された NotAuthenticatedError は GlobalErrorBoundary でキャッチされ、
// LocalStorage にキャッシュしたトークンを削除した上でログイン画面にリダイレクトする

type LoovClientInput = {
  url: string
  method: string
  params?: Record<string, string | number | boolean>
  headers?: Record<string, string>
  data?: unknown
}

type LoovClient = <Response>(input: LoovClientInput) => Promise<Response>
type LoovClientFetcher = (input: LoovClientInput) => Promise<Response>

const buildLoovClient =
  (fetcher: LoovClientFetcher): LoovClient =>
  async (input) => {
    const response = await fetcher(input)

    if (response.ok) {
      const contentType = response.headers.get('Content-Type')
      if (contentType?.startsWith('application/json')) {
        return response.json()
      } else {
        return response.blob()
      }
    }

    if (response.status === 401) {
      throw new NotAuthenticatedError()
    }

    throw new ApiError(response)
  }

// ---------- Utilities ---------- ///
const buildUrl = (path: string, params?: Record<string, string | number | boolean>): URL => {
  const baseUrl = ensure(process.env['NEXT_PUBLIC_API_ENDPOINT'])
  const searchParams = params
    ? `?${new URLSearchParams(mapValues(params, (value) => value.toString())).toString()}`
    : ''
  return new URL(`${path}${searchParams}`, baseUrl)
}

// MEMO: 下記 issue が解消されたか確認するための経過観察ログ
// 2024/12/31 までに不自然なログアウトが観察されない場合は削除してよい
// Ref: https://linear.app/loov/issue/LOOV-129/頻繁にログアウトされてしまう
const addAuthLog = (eventName: string, request: Request): void => {
  const newLog = {
    eventName,
    request,
    timestamp: new Date().toISOString(),
  }
  const currentLog = JSON.parse(sessionStorage.getItem('AUTH_EVENT_LOG') ?? '[]')
  sessionStorage.setItem('AUTH_EVENT_LOG', JSON.stringify([...currentLog, newLog]))
}

// ---------- Public Endpoint Client ---------- ///
const publicEndpointFetcherProvider: LoovClientFetcher = ({ method, headers, data, url: path, params }) => {
  return fetch(buildUrl(path, params), {
    method,
    ...(headers && { headers: new Headers(headers) }),
    ...(data ? { body: JSON.stringify(data) } : {}),
    keepalive: path.startsWith('/public/lead-session-events') && method === 'POST',
  })
}

// MEMO: そのまま export すると orval が認識しないため、関数に wrap して export する
const _loovPublicEndPointClient: LoovClient = buildLoovClient(publicEndpointFetcherProvider)
export const loovPublicEndPointClient: LoovClient = (input) => _loovPublicEndPointClient(input)

// ---------- Private Endpoint Client ---------- ///

class FailedRefreshTokenError extends Error {}

const refreshAccessTokenProvider = () => {
  // Refresh token Request が重複しないように排他制御する
  let tokenPromise: Promise<string> | null = null

  return async (refreshToken: string) => {
    tokenPromise =
      // 他のリクエストがリフレッシュトークンを取得中の場合は待つ
      tokenPromise ??
      cognitoClient
        .send(
          new InitiateAuthCommand({
            ClientId: process.env['NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID'],
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: { REFRESH_TOKEN: refreshToken },
          }),
        )
        .then((result) => {
          if (!result.AuthenticationResult?.AccessToken) {
            throw new FailedRefreshTokenError('Failed to get access token')
          }

          // localStorage に保存
          localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, result.AuthenticationResult.AccessToken)
          return result.AuthenticationResult.AccessToken
        })
        .catch((e) => {
          throw new FailedRefreshTokenError(e instanceof Error ? e.message : String(e))
        })
        .finally(() => {
          // リクエストが終了したら tokenPromise をクリア
          tokenPromise = null
        })
    return tokenPromise
  }
}
const defaultRefreshAccessToken = refreshAccessTokenProvider()

const privateEndpointFetcherProvider = (refreshAccessToken = defaultRefreshAccessToken) => {
  return async ({ url: path, headers, method, params, data }: LoovClientInput) => {
    const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN)
    const switchTenantId = localStorage.getItem(LocalStorageKey.SWITCH_TENANT_ID)
    if (!accessToken || !refreshToken) {
      throw new NotAuthenticatedError()
    }

    const header = new Headers(headers)
    // 認証トークンの追加
    header.set('Authorization', `Bearer ${accessToken}`)
    if (switchTenantId) {
      // システム管理者がテナントの切替を行っている場合はヘッダーを追加
      header.set('X-tenant-id', switchTenantId)
    }

    const request = new Request(buildUrl(path, params), {
      method: method,
      headers: header,
      ...(data ? { body: JSON.stringify(data) } : {}),
    })

    const response = await fetch(request)
      .then(async (response) => {
        if (response.status === 401) {
          addAuthLog('NOT_AUTHENTICATED', request)
          // 401 の場合は Access Token をリフレッシュしてリトライ
          const headers = new Headers(request.headers)
          headers.set('Authorization', `Bearer ${await refreshAccessToken(refreshToken)}`)
          return fetch(new Request(request, { headers }))
        }
        return response
      })
      .catch((e) => {
        if (e instanceof FailedRefreshTokenError) {
          addAuthLog('TOKEN_REFRESH_FAILED', request)
          throw new NotAuthenticatedError(e.message)
        }
        throw e
      })

    return response
  }
}

// MEMO: そのまま export すると orval が認識しないため、関数に wrap して export する
const _loovPrivateEndpointClient: LoovClient = buildLoovClient(privateEndpointFetcherProvider())
export const loovPrivateEndpointClient: LoovClient = (input) => _loovPrivateEndpointClient(input)
