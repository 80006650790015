import { chakra, Image } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import Loading from './elements/Loading'
import { VisualMediaProps } from './VisualMedia'

export type VisualMediaPresentationProps = VisualMediaProps & {
  Image: typeof Image
  createElement: typeof document.createElement
}

const VisualMediaPresentation: FC<VisualMediaPresentationProps> = ({
  commonProps,
  imageProps,
  videoProps,
  Image,
  createElement,
}) => {
  const [type, setType] = useState<'image' | 'video' | 'none'>()

  useEffect(() => {
    if (!commonProps?.src) {
      return setType('none')
    }

    // 読込中はローディングを表示する
    setType(undefined)

    // ファイルの種類を判定して要素を切り替える
    // NOTE: img 要素に読み込ませると、Safari では mp4 などの動画ファイルも img 要素で読み込めてしまう上に、
    // 表示上の不具合（画面がちらつき他のvideo要素がmuteになる）が発生する
    const video = createElement('video')
    video.addEventListener('error', () => setType('image'), { once: true })
    video.addEventListener('loadedmetadata', () => setType('video'), { once: true })
    video.src = commonProps.src
    video.load()
  }, [commonProps?.src, createElement])

  return type === 'none' ? null : type === 'video' ? (
    <chakra.video autoPlay muted loop playsInline {...commonProps} {...videoProps} data-testid="video" />
  ) : type === 'image' ? (
    <Image {...commonProps} {...imageProps} />
  ) : (
    <Loading data-testid="loading" />
  )
}

export default VisualMediaPresentation
