import { FC } from 'react'
import { JoinedPublishedFormInput } from '../orval/loovPublic'
import FormSlideInputsPresentation from './FormSlideInputsPresentation'

export type FormSlideInputsProps = {
  inputs: JoinedPublishedFormInput[]
  privacyPolicyUrl: string
  privacyPolicyText: string
  privacyPolicyCheckboxText: string
  buttonText: string
  onSubmit: (data: Record<string, string | string[]>) => void
}

const FormSlideInputs: FC<FormSlideInputsProps> = (props) => {
  return <FormSlideInputsPresentation {...props} />
}

export default FormSlideInputs
