import { FC } from 'react'
import SwrKey from '../commons/swrKey'
import useIsMobile from '../hooks/useIsMobile'
import useSuspenseSwr, { immutableOptions } from '../hooks/useSuspenseSwr'
import {
  DesktopDisplayHorizontalOffsetType,
  DesktopDisplayType,
  DesktopDisplayVerticalOffsetType,
  getLatestPublishedScenario,
  MobileDisplayType,
} from '../orval/loovPublic'
import EmbeddedFloatingScenarioPlayerPresentation from './EmbeddedFloatingScenarioPlayerPresentation'
import EmbeddedMobileCustomFloat from './EmbeddedMobileCustomFloat'
import EmbeddedDesktopFloat from './needsTests/ScenarioPlayer/EmbeddedDesktopFloat'
import EmbeddedMobileBottomFloat from './needsTests/ScenarioPlayer/EmbeddedMobileBottomFloat'
import EmbeddedMobileSideFloat from './needsTests/ScenarioPlayer/EmbeddedMobileSideFloat'
import ScenarioPlayerModal from './needsTests/ScenarioPlayer/ScenarioPlayerModal'
import ScenarioPlayerProvider from './needsTests/ScenarioPlayer/ScenarioPlayerProvider'

export type EmbeddedFloatingScenarioPlayerProps = {
  scenarioId: string
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  mobileDisplayType: MobileDisplayType
  scenarioMobileThumbnailId: string | null
  preview?: boolean
}

const EmbeddedFloatingScenarioPlayer: FC<EmbeddedFloatingScenarioPlayerProps> = ({ scenarioId, ...props }) => {
  const { data: publishedScenario } = useSuspenseSwr(
    [SwrKey.PUBLISHED_SCENARIOS, { scenarioId }],
    () => getLatestPublishedScenario({ scenarioId }),
    immutableOptions,
  )

  return (
    <EmbeddedFloatingScenarioPlayerPresentation
      useIsMobile={useIsMobile}
      EmbeddedDesktopFloat={EmbeddedDesktopFloat}
      EmbeddedMobileBottomFloat={EmbeddedMobileBottomFloat}
      EmbeddedMobileSideFloat={EmbeddedMobileSideFloat}
      EmbeddedMobileCustomFloat={EmbeddedMobileCustomFloat}
      ScenarioPlayerProvider={ScenarioPlayerProvider}
      ScenarioPlayerModal={ScenarioPlayerModal}
      publishedScenario={publishedScenario}
      {...props}
    />
  )
}

export default EmbeddedFloatingScenarioPlayer
