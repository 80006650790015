/**
 * ユーザーのWebサイトに埋め込みでLOOV動画を表示させるためのスクリプト
 */
import EmbeddedFloatingScenarioPlayer from '../components/EmbeddedFloatingScenarioPlayer'
import EmbeddedCodeScenarioPlayer from '../components/needsTests/ScenarioPlayer/EmbeddedCodeScenarioPlayer'
import { MobileDisplayType } from '../orval/loov'
import {
  DesktopDisplayHorizontalOffsetType,
  DesktopDisplayType,
  DesktopDisplayVerticalOffsetType,
  EmbedScriptFloatConfig,
  getEmbedScript,
} from '../orval/loovPublic'
import embeddedRender from './utils/embeddedRender'

/**
 * Globals
 * 外部スクリプトでグローバル変数として定義しておく
 */
declare global {
  //  eslint-disable-next-line no-var
  var LOOV_SID: unknown // scenarioId
  //  eslint-disable-next-line no-var
  var LOOV_M: unknown // mobileDisplayType
  //  eslint-disable-next-line no-var
  var LOOV_D: unknown // desktopDisplayType
  //  eslint-disable-next-line no-var
  var LOOV_CP: unknown // customMobileDisplayPosition
  //  eslint-disable-next-line no-var
  var LOOV_MTID: unknown // scenarioMobileThumbnailId
  //  eslint-disable-next-line no-var
  var LOOV_DD: unknown // isDuplicated
  //  eslint-disable-next-line no-var
  var dataLayer: unknown // GTM or GA
}

type FloatingConfig = Pick<
  EmbedScriptFloatConfig,
  | 'scenarioId'
  | 'desktopDisplayType'
  | 'desktopDisplayVerticalOffset'
  | 'desktopDisplayVerticalOffsetType'
  | 'desktopDisplayHorizontalOffset'
  | 'desktopDisplayHorizontalOffsetType'
  | 'mobileDisplayType'
  | 'scenarioMobileThumbnailId'
>
;(() => {
  /**
   * Execution
   */
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', main)
  } else {
    main()
  }

  /**
   * Functions
   */
  async function main() {
    // 排他制御
    if (typeof window.LOOV_DD !== 'undefined') {
      throw new Error(`LOOV のスクリプトが重複しています`)
    } else {
      window.LOOV_DD = true
    }

    // ポップアップ型のレンダリング
    const floatingConfig = await getFloatingConfig()
    if (floatingConfig) {
      embeddedRender(
        <EmbeddedFloatingScenarioPlayer
          scenarioId={floatingConfig.scenarioId}
          desktopDisplayType={floatingConfig.desktopDisplayType}
          desktopDisplayVerticalOffset={floatingConfig.desktopDisplayVerticalOffset}
          desktopDisplayVerticalOffsetType={floatingConfig.desktopDisplayVerticalOffsetType}
          desktopDisplayHorizontalOffset={floatingConfig.desktopDisplayHorizontalOffset}
          desktopDisplayHorizontalOffsetType={floatingConfig.desktopDisplayHorizontalOffsetType}
          mobileDisplayType={floatingConfig.mobileDisplayType}
          scenarioMobileThumbnailId={floatingConfig.scenarioMobileThumbnailId}
        />,
        { parent: document.body },
      )
    }

    // カスタム型のレンダリング
    const targets = document.querySelectorAll(`[data-loov-sid]`)
    for (const target of targets) {
      if (target instanceof HTMLElement) {
        const scenarioId = target.getAttribute('data-loov-sid')
        if (scenarioId) {
          embeddedRender(<EmbeddedCodeScenarioPlayer scenarioId={scenarioId} />, {
            parent: target,
            shadowHostStyle: { height: '100%' },
          })
        }
      }
    }
  }

  /**
   * 2024-01-XX 以後のスクリプトは、IDからサーバに問い合わせて設定値を取得する
   *
   * それ以前のレガシーなスクリプトは、グローバル変数から設定値を取得する
   */
  async function getFloatingConfig(): Promise<FloatingConfig | null> {
    // スクリプトIDから設定値を取得
    const scriptId = document.getElementById('loov-embed-script')?.getAttribute('data-script-id')
    if (scriptId) {
      const { floatConfig } = await getEmbedScript(scriptId)
      return floatConfig
    }

    // スクリプトIDが見つからない場合はレガシースクリプトなので、グローバス変数から設定値を取得
    const floatingScenarioId = getGlobalScenarioId()
    if (floatingScenarioId) {
      return {
        scenarioId: floatingScenarioId,
        desktopDisplayType: getGlobalDesktopDisplayType(),
        desktopDisplayVerticalOffset: 50,
        desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType.FROM_BOTTOM,
        desktopDisplayHorizontalOffset: 50,
        desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType.FROM_RIGHT,
        mobileDisplayType: getGlobalMobileDisplayType(),
        scenarioMobileThumbnailId: getGlobalScenarioMobileThumbnailId(),
      }
    }

    return null
  }

  function getGlobalScenarioId(): string | undefined {
    if (typeof window.LOOV_SID === 'undefined') return undefined // 変数未定義
    if (typeof window.LOOV_SID === 'string') return window.LOOV_SID

    throw Error(`LOOVのスクリプトが正しくありません。 LOOV_SID is an unexpected value: ${LOOV_SID}`)
  }

  function getGlobalDesktopDisplayType(): DesktopDisplayType {
    if (typeof window.LOOV_D === 'undefined') return DesktopDisplayType.BOTTOM_RIGHT // 変数未定義
    if (window.LOOV_D === 'r') return DesktopDisplayType.BOTTOM_RIGHT
    if (window.LOOV_D === 'l') return DesktopDisplayType.BOTTOM_LEFT

    throw Error(`LOOVのスクリプトが正しくありません。 LOOV_D is an unexpected value: ${LOOV_D}`)
  }

  function getGlobalMobileDisplayType(): MobileDisplayType {
    if (typeof window.LOOV_M === 'undefined') return MobileDisplayType.BOTTOM_RIGHT // 変数未定義
    if (window.LOOV_M === 'br') return MobileDisplayType.BOTTOM_RIGHT
    if (window.LOOV_M === 'bl') return MobileDisplayType.BOTTOM_LEFT
    if (window.LOOV_M === 'sl') return MobileDisplayType.SIDE_LEFT
    if (window.LOOV_M === 'sr') return MobileDisplayType.SIDE_RIGHT
    if (window.LOOV_M === 'n') return MobileDisplayType.NONE
    if (window.LOOV_M === 'c') {
      if (typeof window.LOOV_CP === 'undefined') {
        throw Error('LOOVのスクリプトが正しくありません。 LOOV_CP is not defined.')
      }

      if (window.LOOV_CP === 'lt') return MobileDisplayType.CUSTOM_TOP_LEFT
      if (window.LOOV_CP === 'lm') return MobileDisplayType.CUSTOM_SIDE_LEFT
      if (window.LOOV_CP === 'lb') return MobileDisplayType.CUSTOM_BOTTOM_LEFT
      if (window.LOOV_CP === 'rt') return MobileDisplayType.CUSTOM_TOP_RIGHT
      if (window.LOOV_CP === 'rm') return MobileDisplayType.CUSTOM_SIDE_RIGHT
      if (window.LOOV_CP === 'rb') return MobileDisplayType.CUSTOM_BOTTOM_RIGHT

      throw Error(`LOOVのスクリプトが正しくありません。 LOOV_CP is an unexpected value: ${window.LOOV_CP}`)
    }

    throw Error(`LOOVのスクリプトが正しくありません。LOOV_M is an unexpected value: ${window.LOOV_M}`)
  }

  function getGlobalScenarioMobileThumbnailId(): string | null {
    if (typeof window.LOOV_MTID === 'undefined') return null // 変数未定義
    if (typeof window.LOOV_MTID === 'string') return window.LOOV_MTID

    throw Error(`LOOVのスクリプトが正しくありません。 LOOV_MTID is an unexpected value: ${window.LOOV_MTID}`)
  }
})()
